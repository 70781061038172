import { createContext, useContext, useEffect, useState } from 'react';
import { 
  User,
  signInWithPopup,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';
import { auth, googleProvider } from '../firebase-config';
import { BaseUser } from '../types';
import { upsertUser } from '../handlers/db';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase-config';

const formatAuthUser = (user: User): BaseUser => {
    return {
      uid: user.uid,
      email: user.email,
      plan: null,
      stripeCustomerId: null,
      subscriptionId: null,
      generationsLeft: 3
    };
  };

interface AuthContextType {
  currentUser: BaseUser | null;
  signInWithGoogle: () => Promise<BaseUser>;
  logout: () => Promise<void>;
  userLoading: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<BaseUser | null>(null);
  const [userLoading, setUserLoading] = useState(true);

  async function signInWithGoogle() {
    try {
      const userCred = await signInWithPopup(auth, googleProvider);
      const user = formatAuthUser(userCred.user);
      const upsertedUser = await upsertUser(user);
      setCurrentUser(upsertedUser as BaseUser);
      return upsertedUser as BaseUser;
    } catch (error) {
      console.error('Sign in error:', error);
      throw error;
    }
  }

  async function logout() {
    return signOut(auth);
  }

  useEffect(() => {
    let unsubscribeDoc: (() => void) | undefined;
    
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          // Get the user data from Firestore
          const userRef = doc(db, `User/${user.uid}`);
          unsubscribeDoc = onSnapshot(userRef, (doc) => {
            if (doc.exists()) {
              setCurrentUser({ ...doc.data(), uid: user.uid } as BaseUser);
            } else {
              // If document doesn't exist, create it with default values
              const formattedUser = formatAuthUser(user);
              upsertUser(formattedUser);
              setCurrentUser(formattedUser);
            }
            setUserLoading(false);
          });
        } else {
          setCurrentUser(null);
          setUserLoading(false);
        }
      } catch (error) {
        console.error('Auth state change error:', error);
        setCurrentUser(null);
        setUserLoading(false);
      }
    });

    return () => {
      unsubscribe();
      if (unsubscribeDoc) {
        unsubscribeDoc();
      }
    };
  }, []);

  // Add error boundary
  if (userLoading) {
    return <div>Loading...</div>; // Or your loading component
  }

  const value = {
    currentUser,
    signInWithGoogle,
    logout,
    userLoading
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}