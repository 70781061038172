import { memo, useCallback, useEffect, useState, useRef } from 'react'
import { Canvas, useLoader, useThree, useFrame } from '@react-three/fiber'
import { Grid, Center, GizmoHelper, GizmoViewport, AccumulativeShadows, RandomizedLight, OrbitControls, Environment, useGLTF } from '@react-three/drei'
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from './firebase-config'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import * as THREE from 'three'
import { Login } from './components/Login'
import { useAuth } from './contexts/AuthContext'
import { createUserGenerationDoc } from './handlers/db';
import { GenerationTrigger } from './types';
import { motion, AnimatePresence } from 'framer-motion'
import { loadStripe } from '@stripe/stripe-js';

const SERVER_URL = "https://classroom-be-production.up.railway.app"

// Initialize Stripe (add this near other constants)
const stripePromise = loadStripe("pk_live_51Q1vmuELzxPZzuBzLbxFqUm1mzdKbiCVxzydMMpB1UQHqABSWQhdEBHugKmEGw08xkSN1sGkc1EyGpKwOxAvXEY700ngN0sBMo");

// Add new components
const Shadows = memo(() => (
  <AccumulativeShadows temporal frames={100} color="#9d4b4b" colorBlend={0.5} alphaTest={0.7} scale={20}>
    <RandomizedLight amount={8} radius={4} position={[5, 5, -10]} />
  </AccumulativeShadows>
))

// @ts-ignore
function Suzi(props) {
  const { nodes } = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/suzanne-high-poly/model.gltf')
  return (
    // @ts-ignore
    <mesh castShadow receiveShadow geometry={nodes.Suzanne.geometry} {...props}>
      <meshStandardMaterial color="#9d4b4b" />
    </mesh>
  )
}

// Add Result component to display the GLB model
function Result({ url }: { url: string }) {
  const gltf = useLoader(GLTFLoader, url)
  const { camera } = useThree()
  
  useEffect(() => {
    // Calculate the bounding box of the model
    const box = new THREE.Box3().setFromObject(gltf.scene)
    const center = box.getCenter(new THREE.Vector3())
    const size = box.getSize(new THREE.Vector3())
    
    // Check if camera is PerspectiveCamera
    if (camera instanceof THREE.PerspectiveCamera) {
      const maxDim = Math.max(size.x, size.y, size.z)
      const fov = camera.fov * (Math.PI / 180)
      const distance = maxDim / (2 * Math.tan(fov / 2))
      
      camera.position.set(
        center.x + distance * 1.5,
        center.y + distance * 1.5,
        center.z + distance * 1.5
      )
    }
    camera.lookAt(center)
    
    // Update camera
    camera.updateProjectionMatrix()
  }, [gltf, camera])

  return <primitive object={gltf.scene} />
}

type ACTIVE = "IMAGE" | "TEXT" | null;

// Add this helper function at the top level
const downloadGLB = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const downloadUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = 'model.glb';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(downloadUrl);
};

// Add this new component for the spinning cubes
function SpinningCube({ position, loading, tilted = false }: { position: [number, number, number], loading: boolean, tilted?: boolean }) {
  const meshRef = useRef<THREE.Mesh>(null);
  const speedRef = useRef(3); // Initial speed

  useFrame((state, delta) => {
    if (loading && meshRef.current) {
      speedRef.current += 0.2 * delta;
      meshRef.current.rotation.y += speedRef.current * delta;
    } else {
      speedRef.current = 3;
    }
  });

  return (
    <Center top position={position}>
      <mesh 
        ref={meshRef} 
        castShadow 
        rotation={tilted ? [Math.PI / 4, 0, Math.PI / 4] : [0, 0, 0]}
      >
        <boxGeometry args={[0.7, 0.7, 0.7]} />
        <meshStandardMaterial color="#9d4b4b" />
      </mesh>
    </Center>
  );
}

// Add this new component above the App component
function PaywallModal({ 
  isOpen, 
  onClose,
  user
}: { 
  isOpen: boolean; 
  onClose: () => void;
  user: any; // Replace 'any' with your user type
}) {
  const handleUpgrade = async (price_id: string) => {
    try {
      const response = await fetch(`${SERVER_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: user?.uid,
          price_id,
        }),
      });

      const session = await response.json();
      const stripe = await stripePromise;
      const { error } = await stripe!.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (error) {
        console.error('Error:', error);
      }
    } catch (error) {
      console.error('Error creating Stripe session:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            onClose();
          }
        }}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          className="bg-white rounded-2xl p-8 max-w-md w-full shadow-2xl"
        >
          <div className="text-center">
            <div className="mb-4">
              <span className="text-4xl">✨</span>
            </div>
            <h2 className="text-2xl font-bold mb-2">Upgrade to Pro</h2>
            <div className="mb-6">
              <div className="text-3xl font-bold text-blue-600">$10<span className="text-lg text-gray-600">/month</span></div>
              <p className="text-gray-600 mt-2">
                You've used all your free generations. Upgrade to Pro for unlimited access!
              </p>
            </div>
            
            <div className="bg-gray-50 rounded-xl p-6 mb-6">
              <h3 className="text-xl font-semibold mb-4">Pro Plan Features</h3>
              <ul className="text-left space-y-3">
                {[
                  'Unlimited 3D generations',
                  'Priority processing',
                  'Advanced customization options',
                  'Commercial usage rights',
                ].map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <span className="text-green-500 mr-2">✓</span>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>

            <div className="space-y-4">
              <button
                onClick={() => handleUpgrade('price_1QUE54ELzxPZzuBzn77mOyxW')} // Replace with your Stripe price ID
                className="w-full bg-gradient-to-r from-blue-600 to-blue-700 text-white py-3 px-6 rounded-lg font-semibold hover:from-blue-700 hover:to-blue-800 transition-all duration-200 shadow-lg hover:shadow-xl"
              >
                Upgrade to Pro
              </button>
              
              <button
                onClick={onClose}
                className="w-full text-gray-500 py-2 px-6 hover:text-gray-700 transition-colors"
              >
                Maybe Later
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
}

function App() {
  // Replace useState with useControls
  const gridConfig = {
    gridSize: [10.5, 10.5],
    cellSize: 0.6,
    cellThickness: 1,
    cellColor: '#9d4b4b',
    sectionSize: 3.3,
    sectionThickness: 1.5,
    sectionColor: '#9d4b4b',
    fadeDistance: 25,
    fadeStrength: 1,
    followCamera: false,
    infiniteGrid: true
  }
  const { gridSize, ...restGridConfig } = gridConfig

  const [image, setImage] = useState<File | null>(null)
  const [preview, setPreview] = useState<string | null>(null)
  const [pendingJob, setPendingJob] = useState<string | null>(null);
  const [meshLoading, setMeshLoading] = useState<boolean>(false);
  const [result, setResult] = useState<string | null>(null);
  const [paywallModal, setPaywallModal] = useState<boolean>(false);

  const { currentUser, userLoading, logout, signInWithGoogle } = useAuth();
  console.log(currentUser);

  const [text, setText] = useState("");

  useEffect(() => {
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview)
      }
    }
  }, [preview])

  const handleFileSelect = useCallback((file: File) => {
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setImage(file)
      // Create preview URL
      const previewUrl = URL.createObjectURL(file)
      setPreview(previewUrl)
    } else {
      alert('Please upload a PNG or JPG image')
    }
  }, [])

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    handleFileSelect(file)
  }, [handleFileSelect])

  // Handle drag events
  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault()
  }, [])

  // Handle file input change
  const handleFileInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      handleFileSelect(e.target.files[0])
    }
  }, [handleFileSelect])

  const determineActiveInput = () => {
    if (image != null) {
      return "IMAGE";
    }
    if (text.length > 0) {
      return "TEXT";
    }
    return null;
  }

  const triggerGeneration = async () => {
    if (!image) {
      alert('Please provide an image first');
      return;
    }

    setMeshLoading(true);
    let curUser = currentUser;

    try {
      // If not logged in, wait for sign in to complete
      if (!currentUser) {
        curUser = await signInWithGoogle();
        // Add a small delay to ensure auth state is updated
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      // Verify user is now logged in
      if (!curUser) {
        throw new Error('Authentication failed');
      }

      if ((curUser.plan === 'FREE' || !curUser.plan) && curUser.generationsLeft <= 0) {
        setMeshLoading(false);
        setPaywallModal(true);
        return;
      }

      // Get upload URL for the image
      const uploadUrlResponse = await fetch('https://3d-serverless.vercel.app/api/get-upload-urls', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          containerName: '3dstudio',
          fileNames: ['image.png'],
        })
      });

      if (!uploadUrlResponse.ok) {
        throw new Error('Failed to get upload URL');
      }

      const { uploadUrls, uploadDir } = await uploadUrlResponse.json();
      
      // Upload the image using the signed URL
      const uploadResponse = await fetch(uploadUrls[0], {
        method: 'PUT',
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': image.type
        },
        body: image
      });

      if (!uploadResponse.ok) {
        throw new Error('Failed to upload image');
      }

      console.log('Image uploaded successfully to directory:', uploadDir);

      if (!curUser) {
        throw new Error('User must be logged in');
      }

      const docId = await createUserGenerationDoc(curUser);
      // Extract just the document ID from the full path
      const generationId = docId.split('/').pop()!;
      
      const generationTrigger: GenerationTrigger = {
        docId,
        image: uploadUrls[0],
      };

      await fetch('https://3d-serverless.vercel.app/api/trigger-generation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(generationTrigger)
      });
      setPendingJob(generationId);

    } catch (error) {
      setMeshLoading(false);
      console.error('Error uploading image:', error);
      alert('Failed to process request. Please try again.');
      return;
    }
  }

  const activeInput: ACTIVE = determineActiveInput();

  useEffect(() => {
    if (!pendingJob || !currentUser) return;

    const unsubscribe = onSnapshot(doc(db, 'User', currentUser.uid, 'Generation', pendingJob), (doc) => {
      const data = doc.data();
      if (data?.status === 'DONE') {
        const modelUrl = `https://photoshopai.blob.core.windows.net/3dstudio/${data.dirPath}/result.glb`
        setResult(modelUrl);
        setMeshLoading(false);
        setPendingJob(null);
      } else if (data?.status === 'ERROR') {
        setMeshLoading(false);
        setPendingJob(null);
        alert('Generation failed. Please try again.');
      }
    });

    return () => unsubscribe();
  }, [pendingJob, currentUser?.uid]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <PaywallModal 
        isOpen={paywallModal} 
        onClose={() => setPaywallModal(false)}
        user={currentUser}
      />
      
      <Canvas
        shadows
        camera={{ position: [10, 12, 12], fov: 25 }}
        gl={{ alpha: false }} // Disable transparency
        style={{ background: '#303035' }} // Add background color
      >
        <group position={[0, -0.5, 0]}>
          {result ? (
            <Center top>
              <Result url={result} />
            </Center>
          ) : (
            <>
              <Center top>
                <Suzi rotation={[-0.63, 0, 0]} scale={2} />
              </Center>
              <SpinningCube position={[-2, 0, 2]} loading={meshLoading} tilted={true} />
              <SpinningCube position={[4, 0, 1]} loading={meshLoading} tilted={false} />
            </>
          )}
          <Shadows />
          <Grid position={[0, -0.01, 0]} args={[gridSize[0], gridSize[1]]} {...restGridConfig} />
        </group>
        <OrbitControls makeDefault />
        <Environment files="potsdamer_platz_1k.hdr" />
        <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
          <GizmoViewport axisColors={['#9d4b4b', '#9d4b4b', '#9d4b4b']} labelColor="white" /> 
        </GizmoHelper>
      </Canvas>
      <div style={{
        position: 'absolute',
        top: '10%',
        bottom: '10%',
        left: '40px',
        background: 'rgba(255, 255, 255, 0.9)',
        padding: '2rem',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        height: '80%',
        overflow: 'auto'
      }}>
        <div style={{ textAlign: 'center', flex: 1 }}>
          <div
            className="border-2 border-dashed border-gray-300 rounded-lg p-8 mb-4 bg-white cursor-pointer"
            onDrop={activeInput !== "TEXT" ? handleDrop : undefined}
            onDragOver={activeInput !== "TEXT" ? handleDragOver : undefined}
            onClick={activeInput !== "TEXT" ? () => document.getElementById('fileInput')?.click() : undefined}
            style={{
              opacity: activeInput === "TEXT" ? 0.5 : 1,
              pointerEvents: activeInput === "TEXT" ? 'none' : 'auto'
            }}
          >
            <input
              type="file"
              id="fileInput"
              accept="image/png,image/jpeg"
              onChange={handleFileInput}
              style={{ display: 'none' }}
            />
            {preview ? (
              <div className="relative">
                <button
                  className="absolute top-[-20px] right-[-20px] w-6 h-6 bg-gray-200 rounded-full flex items-center justify-center hover:bg-gray-300 transition-colors"
                  onClick={(e) => {
                    e.stopPropagation() // Prevent opening file dialog
                    setImage(null)
                    setPreview(null)
                  }}
                >
                  ✕
                </button>
                <img 
                  src={preview} 
                  alt="Preview" 
                  style={{ 
                    maxWidth: '100%', 
                    maxHeight: '200px', 
                    objectFit: 'contain' 
                  }} 
                />
              </div>
            ) : (
              <>
                <p>Drag and Drop an image</p>
                <button className="bg-white border border-gray-300 px-4 py-2 rounded-md cursor-pointer shadow-md hover:shadow-lg transition-shadow">
                  Browse Files
                </button>
              </>
            )}
          </div>
          <button 
            className={`w-full ${
              !image || meshLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 hover:shadow-lg'
            } text-white border-none py-3 rounded transition-all duration-200 ease-in-out`}
            onClick={triggerGeneration}
            disabled={!image || meshLoading}
          >
            {meshLoading ? (
              <div className="flex items-center justify-center gap-2">
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                <span>Generating...</span>
              </div>
            ) : (
              'Generate 3D'
            )}
          </button>
          
          {result && (
            <button 
              className="w-full mt-3 bg-black hover:bg-gray-800 hover:shadow-lg text-white border-none py-3 rounded transition-all duration-200 ease-in-out"
              onClick={() => downloadGLB(result)}
            >
              Download GLB
            </button>
          )}

          {currentUser?.plan === 'FREE' && (
            <p className="mt-2 text-center text-gray-600">
              {currentUser.generationsLeft} Free Generations Left
            </p>
          )}
        </div>
        
        {currentUser ? (
          <button 
            className="w-full mt-4 bg-red-600 hover:bg-red-700 hover:shadow-lg text-white border-none py-3 rounded transition-all duration-200 ease-in-out"
            onClick={() => logout()}
          >
            Logout
          </button>
        ) : (
          <button 
            className="w-full mt-4 bg-blue-600 hover:bg-blue-700 hover:shadow-lg text-white border-none py-3 rounded transition-all duration-200 ease-in-out"
            onClick={() => signInWithGoogle()}
          >
            Sign in
          </button>
        )}
      </div>
    </div>
  )
}

export default App